import {
  Injectable
} from '@angular/core';

import {
  HttpClient
} from '@angular/common/http';

import {
  map
} from 'rxjs/operators';

import * as moment from 'moment';

import {
  Observable,
  BehaviorSubject
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppliamService {

  apiURL: string = 'https://dash.appliam.com/api/';
  appID: number = 79;
  version = 1.31;
  app: any = new BehaviorSubject(false);
  lastFetched: any = null;
  fetchInterval: number = 3600000; //60 * 1000 = 60 seconds = 1 minute * 60 = 1 hour

  constructor(private http: HttpClient) {
    this.getAppLatest().subscribe((app:any) => {
      console.log(`[APPLIAM] ${app.name} App v${this.version}`);
      this.app.next(app);
    });
  }

  getApp() {
    if (this.needFetch()) {
      this.lastFetched = moment().valueOf();
      return this.getAppLatest().pipe(
        map(res => this.app.next(res))
      );
    } else {
      console.log('Returning app cache as observable...');
      return new Observable(subscriber => {
        subscriber.next(this.app);
        subscriber.complete();
      });
    }
  }

  latestReviews() {
    return this.http.get(`${this.apiURL}reviews/${this.appID}`);
  }

  leaveReview(data){
    return this.http.post(`${this.apiURL}review/leave/${this.appID}`, data);
  }

  getAppLatest() {
    return this.http.get(`${this.apiURL}` + `app/${this.appID}`);
  }

  needFetch(): boolean {
    if (this.lastFetched == null) {
      console.log('App has not been fetched yet, fetching...');
      return true;
    }
    if ((moment().valueOf() - this.lastFetched) >= this.fetchInterval) {
      console.log('App has not been fetched within the fetch interval, fetching...');
      return true;
    }
    console.log('App does not need a fetch');
    return false;
  }

  sendAppContact(data) {
    return this.http.post(`https://dash.appliam.com/api/contact/app`, data);
  }

}