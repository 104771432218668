import {
  Injectable
} from '@angular/core';
import {
  AuthenticationService
} from './authentication.service';
import {
  AppliamService
} from './appliam.service';
import {
  Platform
} from '@ionic/angular';
import {
  BehaviorSubject
} from 'rxjs';
import {
  Storage
} from '@ionic/storage';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { MenuService } from './menu.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  public user_addresses = new BehaviorSubject([]);
  apiUrl = 'https://dash.appliam.com/api/';
  logged_in: boolean = false;

  constructor(
    public auth: AuthenticationService,
    public appliam: AppliamService,
    public menuService: MenuService,
    public platform: Platform,
    public storage: Storage,
    public http: HttpClient
  ) {
    this.platform.ready().then(() => {
      this.auth.authState.subscribe(auth => {
        this.user_addresses.next([]);
        this.logged_in = auth;
        if (auth) {
          //user is authenticated
          console.log('[ADDRESS SERVICE] user is authenticated');
          this.auth.user.subscribe((user: any) => {
            this.user_addresses.next(user.addresses);
          });
        } else {
          //user is not authenticated
          console.log('[ADDRESS SERVICE] user is not authenticated');
          this.storage.get('USER_ADDRESS').then(res => {
            if (res) {
              this.user_addresses.next(JSON.parse(res));
            }
          });
        }
      });
    });
  }

  storeAddress(data) {
    //looks more complex than it is
    return new Promise(async (resolve, reject) => {
      let config;
      await this.menuService.config.subscribe(res => {
        config = res;
      });

      this.validateAddress(data).subscribe((res:any) => {
        console.log('validate addy res..', res);
        if(res.success){
          //address is validated ... store it
          if(this.logged_in){
            //user is logged in , refresh account
            this.auth.refreshUser();
            resolve();
          }else{
            //guest user
            let a = this.user_addresses.value;
            a.push(res.address);
            this.update(a);
            resolve();
          }
        }else{
          //res was not a success so reject the promise
          reject(res);
        }
      })

      /* OLD WAY
      if (this.logged_in) {
        //user is authed ... add to their account
        this.addUserAddress(data).subscribe((res: any) => {
          if (res.success) {
            //all is good, we need to refresh the user & addresses ... user is sent back with this response
            this.auth.refreshUser();
            resolve();
          } else {
            //something went wrong
            reject(res);
          }
        });
      } else {
        //user is guest
        this.getDistance(data).subscribe((res: any) => {
          console.log({res});
          if (res.success) {
            let a = this.user_addresses.value;
            data.distance = res.distance;
            a.push(data);
            this.update(a);
            resolve();
          } else {
            //PROBS TOO FAR AWAY ... COULD USE res.message IF IT EXISTS
            reject(res);
          }
        });
      }*/

    });

  }

  update(data) {
    this.storage.set('USER_ADDRESS', JSON.stringify(data)).then(res => {
      this.user_addresses.next(data);
    });
  }

  validateAddress(a){
    //validate any address for use with this restaurant regardless of delivery config.. on success update the local array
    let user_id = null;
    if(this.logged_in){
      this.auth.user.subscribe((user: any) => {
        user_id = user.id;
      });
    }
    let data = {
      user_id: user_id,
      ...a
    };
    console.log('[ADDRESS SERVICE] Validating Address with data...', data);
    return this.http.post(`${this.apiUrl}address/validate/${this.appliam.appID}`, data);
  }

  getDistance(a) {
    return this.http.post(`${this.apiUrl}address/distance/${this.appliam.appID}`, a);
  }

  addUserAddress(a) {
    //user/address/new
    console.log('adding user address');
    const headers = new HttpHeaders({
      'Authorization': this.auth.token["token_type"] + " " + this.auth.token["access_token"]
    });
    let data = {
      app_id: this.appliam.appID,
      ...a
    };
    return this.http.post(`${this.apiUrl}user/address/new`, data, {
      headers: headers
    });
  }

  addAddress(a){
    ///address/add/{app}
    let user_id = null;
    if(this.logged_in){
      this.auth.user.subscribe((user: any) => {
        user_id = user.id;
      });
    }
    let data = {
      user_id: user_id,
      ...a
    };
    return this.http.post(`${this.apiUrl}address/add/${this.appliam.appID}`, data);
  }

}