import {
  Injectable
} from '@angular/core';
import {
  Storage
} from '@ionic/storage';
import {
  BehaviorSubject
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  cart: any = [];

  get_cart = new BehaviorSubject([]);

  total_items = new BehaviorSubject(0);

  subtotal = new BehaviorSubject(0);

  constructor(
    public storage: Storage
  ) {
    this.storage.get('cart').then(cart => {
      if(cart){
        this.cart = JSON.parse(cart);
      }
      this.syncCart();
      console.log('[CART]', this.cart);
    }).catch(err => {
      console.error('[CART SERVICE] GET CART ERROR ', err);
    });
    
  }

  async addToCart(item: any) {
    console.log('[CART SERVICE] ITEM => ', item);
    delete item.item.item_options;
    delete item.item.variants;
    if (this.cart) {
      let i = await this.isDuplicate(this.cart, item);
      if (i > -1) {
        console.log({
          i
        });
        this.cart[i].qty += +item.qty;
        this.cart[i].line_total = +(this.cart[i].qty * this.cart[i].subtotal);
      } else {
        this.cart.push(item);
      }
    } else {
      this.cart = [item];
    }
    this.syncCart();
  }

  async removeFromCart(index) {
    this.cart.splice(index, 1);
    this.syncCart();
  }

  async increaseQty(index) {
    this.cart[index].qty++;
    this.cart[index].line_total = +(this.cart[index].qty * this.cart[index].subtotal);
    this.syncCart();
  }

  async decreaseQty(index) {
    if (this.cart[index].qty > 1) {
      this.cart[index].qty--;
      this.cart[index].line_total = +(this.cart[index].qty * this.cart[index].subtotal);
      this.syncCart();
    }
  }


  async isDuplicate(cart, item) {
    //TODO: Check variants
    let foundIndex = -1;
    await cart.map((r, index) => {
      console.log({
        r
      })
      if (r.item.id == item.item.id) {
        if(r.selected_variant != null){
          //has variants.... expand on this check
          //check selected variant id isnt the same
          if(r.selected_variant.id == item.selected_variant.id){
            //same item ... check options
        if (r.options.length > 0 && r.options.length == item.options.length) {
          //they have options and are the same number of options
          let rids = r.options.map(v => v.id);
          let iids = item.options.map(v => v.id);
          if (JSON.stringify(rids.sort()) === JSON.stringify(iids.sort())) {
            foundIndex = index;
          }
        } 
          }else{
            foundIndex = -1;
          }
        }
        
        
      }
    });
    return foundIndex;
  }

  syncCart() {
    this.updateItems();
    this.get_cart.next(this.cart);
    this.storage.set('cart', JSON.stringify(this.cart)).catch(err => {console.error(err)});
  }

  updateItems() {
    let t = this.cart.reduce((acc, val) => {
      return acc + parseFloat(val.qty);
    }, 0);
    let subtotal = this.cart.reduce((acc, val) => {
      return acc + parseFloat(val.line_total);
    }, 0);
    this.subtotal.next(subtotal);
    this.total_items.next(t);
  }

  empty(){
    this.cart = [];
    this.syncCart();
  }

  reorder(cart){
    this.cart = cart;
    this.syncCart();
  }

}