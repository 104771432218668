import {
  Injectable
} from '@angular/core';

import {
  HttpClient
} from '@angular/common/http';

import {
  map
} from 'rxjs/operators';

import * as moment from 'moment';

import {
  Observable,
  BehaviorSubject
} from 'rxjs';

import {
  AppliamService
} from './appliam.service';


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  apiURL: string = 'https://dash.appliam.com/api/';
  menu: any = [];
  config = new BehaviorSubject(null);
  hours = new BehaviorSubject(false);
  postcodeGroups = new BehaviorSubject(null);
  lastFetched: any = null;
  fetchInterval: number = 3600000; //60 * 1000 = 60 seconds = 1 minute * 60 = 1 hour

  constructor(
    public http: HttpClient,
    public appliam: AppliamService,
  ) {
    this.getDeliveryConfig().subscribe((config:any) => {
      this.config.next(config);
      if(config.postcode_deliveries == 1){
        this.getPostcodeGroups().subscribe(data => {
          console.log('postcode groups', data);
          this.postcodeGroups.next(data);
        });
      }
    });
    this.isOpen().subscribe((data: any) => {
      this.hours.next(data);
    });
    setInterval(() => {
      this.getDeliveryConfig().subscribe(config => {
        this.config.next(config);
      });
      this.isOpen().subscribe((data: any) => {
        this.hours.next(data);
      });
    }, 300000); //every 5 minutes, maybe change to be longer? 
  }

  getMenu() {
    if (this.needFetch()) {
      this.lastFetched = moment().valueOf();
      return this.getMenuLatest().pipe(
        map(res => this.menu = res)
      );
    } else {
      console.log('Returning menu cache as observable...');
      return new Observable(subscriber => {
        subscriber.next(this.menu);
        subscriber.complete();
      });
    }
  }

  getPostcodeGroups(){
    return this.http.get(`${this.apiURL}delivery/postcode/groups/${this.appliam.appID}`);
  }

  getMenuLatest() {
    return this.http.get(`${this.apiURL}menu/${this.appliam.appID}`);
  }

  needFetch(): boolean {
    if (this.lastFetched == null) {
      return true;
    }
    if ((moment().valueOf() - this.lastFetched) >= this.fetchInterval) {
      return true;
    }
    return false;
  }

  getCategory(id) {
    return this.http.get(`${this.apiURL}menu/category/${id}`);
  }

  getItem(id) {
    for (const cat of this.menu) {
      let items = cat.items;
      const result = items.filter(i => i.id == id);
      if (result.length > 0) {
        return result[0];
      }
    }
  }

  isOpen() {
    return this.http.get(`${this.apiURL}hours/open/${this.appliam.appID}`);
  }

  getDeliveryConfig() {
    return this.http.get(`${this.apiURL}delivery/config/${this.appliam.appID}`);
  }

  getOrder(id) {
    return this.http.get(`${this.apiURL}order/${id}`);
  }

  sendCashOrder(data){
    return this.http.post(`${this.apiURL}order/${this.appliam.appID}`, data);
  }

}