import {
  Component,
  OnInit,
  Input,
  HostListener,
  OnDestroy
} from '@angular/core';
import {
  ModalController,
  ToastController
} from '@ionic/angular';
import {
  CartService
} from '../services/cart.service';
import {
  CartItem
} from '../interfaces/cart-item.interface';

@Component({
  selector: 'app-add-item-modal',
  templateUrl: './add-item-modal.page.html',
  styleUrls: ['./add-item-modal.page.scss'],
})
export class AddItemModalPage implements OnInit, OnDestroy {

  @Input() item: any = {
    title: ''
  };
  qty: any = "1";
  buyable: boolean = false;
  hasOptions: boolean = false;
  hasVariants: boolean = false;
  hasVariantOptions: boolean = false;
  subtotal: number = 0;
  totalPrice: number = 0;
  requiredOptions: [];
  optionalOptions: [];
  required_variant_options: [] = [];
  optional_variant_options: [] = [];
  variants: [] = [];
  selected_variant: any = null;
  selected_options: any = [];

  constructor(
    public modalCtrl: ModalController,
    public cart: CartService,
    public toastController: ToastController,
  ) {}

  ngOnInit() {
    const modalState = {
      modal : true,
      desc : 'Fake modal state for back button close'
    };
    console.log('MODAL ITEM', this.item);
    history.pushState(modalState, null);
    this.requiredOptions = this.item.item_options.filter(v => v.required == 1);
    this.optionalOptions = this.item.item_options.filter(v => v.required == 0);
    this.variants = this.item.variants;
    this.hasVariantOptions = this.item.variants.some(v => v.item_options.length > 0);
    console.log('has var opt', this.hasVariantOptions);
    this.calculate();
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  @HostListener('window:popstate')
  dismissModal() {
    this.modalCtrl.dismiss();
  }

  updateOptions(event, option) {
    if (option == 'variant') {
      //is a variant
      this.selected_variant = this.variants.filter((v:any) => v.id == event.value)[0];
      this.required_variant_options = this.selected_variant.item_options.filter(v => v.required == 1);
      this.optional_variant_options = this.selected_variant.item_options.filter(v => v.required != 1);
    } else {
      //when they change a checkbox or radio
      let item = option.items.filter(i => i.id == event.value)[0];
      if (event.hasOwnProperty('checked')) {
        //checkbox - multi options
        if (event.checked) {
          this.selected_options.push(item);
        } else {
          this.selected_options = this.selected_options.filter(i => {
            return i.id != item.id;
          });
        }
      } else {
        //radio - single choice
        console.log({event});
        //this will remove any items with same category id ... as radio is only 1 option
        this.selected_options = this.selected_options.filter(i => {
          return i.item_option_category_id != item.item_option_category_id;
        });
        this.selected_options.push(item);
      }
    }
    console.log('selected_options => ', this.selected_options);
    this.calculate();
  }

  increaseQty() {
    +this.qty++;
    this.calculate();
  }

  decreaseQty() {
    +this.qty > 1 ? +this.qty-- : false;
    this.calculate();
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  async addToCart(item) {
    let CartItem: CartItem = {
      item: item,
      selected_variant: this.selected_variant,
      options: this.selected_options,
      subtotal: this.subtotal,
      qty: +this.qty,
      line_total: this.totalPrice
    }
    console.log(CartItem);
    this.cart.addToCart(CartItem);
    const toast = await this.toastController.create({
      message: `${item.title} added to your order`,
      color: 'primary',
      duration: 4000,
      showCloseButton: true
    });
    toast.present();
    this.dismiss();
  }

  calculate() {
    let error = false;
    let total = +this.item.price;
    if (this.item.item_options.length > 0) {
      this.hasOptions = true;
    }
    if(this.variants.length > 0){
      this.hasVariants = true;
    }
    if (!this.hasOptions && !this.hasVariants) {
      //has no options or variants
      this.buyable = true;
    } else {
      //item has options or variants... check for required ones and their status
      if(this.hasVariants){
        if(this.selected_variant != null){
          //WE SET THE BASE ITEM PRICE AS THE VARIANT PRICE
          total = parseFloat(this.selected_variant.price);
        }else{
          //the item has variants but a variant isn't selected... not buyable
          error = true;
        }
      }
      if (this.hasOptions) {
        this.requiredOptions.map((o:any) => {
          let id = o.id;
          let opts = this.selected_options.filter((i:any) => {
            return id == i.item_option_category_id;
          });
          if(opts.length > 0){
            //they have selected options for this required option
          }else{
            //they do not have selected options for this required option
            error = true;
          }
          console.log({o});
        });
        
      }
      //now add all selected options prices to total
      this.selected_options.map((o:any) => {
        total += +o.price;
      });
    }
    if(this.item.paused == 1){
      error = true;
    }
    this.buyable = !error;
    this.subtotal = +(total).toFixed(2);
    this.totalPrice = +(total * +this.qty).toFixed(2);
  }

}