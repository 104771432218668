import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AppliamService } from './services/appliam.service';
import { AddItemModalPageModule } from './add-item-modal/add-item-modal.module';
import { AddAddressModalPageModule } from './add-address-modal/add-address-modal.module';
import { CardPaymentModalPageModule } from './card-payment-modal/card-payment-modal.module';
import { PaypalPaymentModalPageModule } from './paypal-payment-modal/paypal-payment-modal.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Firebase } from '@ionic-native/firebase/ngx';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from './components/components.module';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios'
    }),
    IonicStorageModule.forRoot({
      name: '__appliam',
         driverOrder: ['websql', 'sqlite', 'indexeddb']
    }),
    AppRoutingModule,
    ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production }),
    HttpClientModule,
    FormsModule,
    AddItemModalPageModule,
    AddAddressModalPageModule,
    CardPaymentModalPageModule,
    PaypalPaymentModalPageModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Firebase,
    AppliamService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
