import {
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  Storage
} from '@ionic/storage';
import {
  ToastController,
  Platform
} from '@ionic/angular';
import {
  BehaviorSubject
} from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import {
  AppliamService
} from './appliam.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  apiUrl: string = 'https://dash.appliam.com/api/';
  authState = new BehaviorSubject(false);
  token: any;
  user = new BehaviorSubject(false);
  admin: any;
  rep: any;
  constructor(
    private router: Router,
    private storage: Storage,
    private platform: Platform,
    public http: HttpClient,
    public toastController: ToastController,
    public appliam: AppliamService
  ) {
    this.platform.ready().then(() => {
      this.ifLoggedIn();
    });
  }

  async ifLoggedIn() {
    await this.storage.get('USER_TOKEN').then((response) => {
      if (response) {
        this.token = response;
        this.authState.next(true);
        this.getUser().subscribe((res: any) => {
          this.user.next(res);
        });
      }
    });
  }

  login(username: string, password: string, error) {
    let credentials = {
      username: username,
      password: password,
    };

    return this.http.post(this.apiUrl + 'login', credentials)
      .subscribe(res => {
        console.log('login res', res);
        this.storage.set('USER_TOKEN', res).then(async (response) => {
          this.token = res;
          this.authState.next(true);
          const toast = await this.toastController.create({
            message: `Logged in successfully`,
            color: 'primary',
            duration: 4000,
            showCloseButton: true
          });
          toast.present();
          this.getUser().subscribe((res: any) => {
            this.user.next(res);
          });
          this.router.navigate(['account']);
        });
      }, (err) => {
        error();
        console.error('USER AUTH ERROR');
      });

  }

  register(formData) {
    return this.http.post(`${this.apiUrl}user/register/${this.appliam.appID}`, formData);
  }

  getUser() {
    const headers = new HttpHeaders({
      'Authorization': this.token["token_type"] + " " + this.token["access_token"]
    });
    return this.http.get(this.apiUrl + 'user', {
      headers: headers
    });
  }

  refreshUser() {
    this.getUser().subscribe((res: any) => {
      this.user.next(res);
    });
  }

  logout() {
    this.storage.remove('USER_TOKEN').then(async () => {
      this.storage.remove('USER_ADDRESS').then(async () => {
        this.router.navigate(['login']);
        this.authState.next(false);
        const toast = await this.toastController.create({
          message: `Logged out successfully`,
          color: 'primary',
          duration: 4000,
          showCloseButton: true
        });
        toast.present();
      });
    });
  }

  isAuthenticated() {
    return this.authState.value;
  }

}