import {
  NgModule
} from '@angular/core';
import {
  PreloadAllModules,
  RouterModule,
  Routes
} from '@angular/router';

const routes: Routes = [{
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'menu/:id',
    loadChildren: () => import('./category/category.module').then(m => m.CategoryPageModule)
  },
  {
    path: 'add-item-modal',
    loadChildren: './add-item-modal/add-item-modal.module#AddItemModalPageModule'
  },
  {
    path: 'cart',
    loadChildren: './cart/cart.module#CartPageModule'
  },
  {
    path: 'menu',
    loadChildren: './menu/menu.module#MenuPageModule'
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginPageModule'
  },
  {
    path: 'register',
    loadChildren: './register/register.module#RegisterPageModule'
  },
  {
    path: 'account',
    loadChildren: './account/account.module#AccountPageModule'
  },
  {
    path: 'checkout',
    loadChildren: './checkout/checkout.module#CheckoutPageModule'
  },
  {
    path: 'opening-hours',
    loadChildren: './opening-hours/opening-hours.module#OpeningHoursPageModule'
  },
  {
    path: 'contact-us',
    loadChildren: './contact-us/contact-us.module#ContactUsPageModule'
  },
  {
    path: 'add-address-modal',
    loadChildren: './add-address-modal/add-address-modal.module#AddAddressModalPageModule'
  },
  {
    path: 'card-payment-modal',
    loadChildren: './card-payment-modal/card-payment-modal.module#CardPaymentModalPageModule'
  },
  {
    path: 'paypal-payment-modal',
    loadChildren: './paypal-payment-modal/paypal-payment-modal.module#PaypalPaymentModalPageModule'
  },
  {
    path: 'order-success',
    loadChildren: './order-success/order-success.module#OrderSuccessPageModule'
  },
  {
    path: 'view-order/:id',
    loadChildren: './view-order/view-order.module#ViewOrderPageModule'
  },
  {
    path: 'reviews',
    loadChildren: './reviews/reviews.module#ReviewsPageModule'
  },
  {
    path: 'terms',
    loadChildren: './terms/terms.module#TermsPageModule'
  },
  {
    path: 'privacy',
    loadChildren: './privacy/privacy.module#PrivacyPageModule'
  },
  {
    path: 'about',
    loadChildren: './about/about.module#AboutPageModule'
  },

];

/*const routes: Routes = [{
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'menu/:id',
    loadChildren: () => import('./category/category.module').then(m => m.CategoryPageModule)
  },
  {
    path: 'add-item-modal',
    loadChildren: './add-item-modal/add-item-modal.module#AddItemModalPageModule'
  },
  {
    path: 'cart',
    loadChildren: './cart/cart.module#CartPageModule'
  },
  {
    path: 'menu',
    loadChildren: './menu/menu.module#MenuPageModule'
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginPageModule'
  },
  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'account', loadChildren: './account/account.module#AccountPageModule' },
  { path: 'checkout', loadChildren: './checkout/checkout.module#CheckoutPageModule' },
  { path: 'opening-hours', loadChildren: './opening-hours/opening-hours.module#OpeningHoursPageModule' },
  { path: 'contact-us', loadChildren: './contact-us/contact-us.module#ContactUsPageModule' },
  { path: 'add-address-modal', loadChildren: './add-address-modal/add-address-modal.module#AddAddressModalPageModule' },
  { path: 'card-payment-modal', loadChildren: './card-payment-modal/card-payment-modal.module#CardPaymentModalPageModule' },
  { path: 'paypal-payment-modal', loadChildren: './paypal-payment-modal/paypal-payment-modal.module#PaypalPaymentModalPageModule' },
  { path: 'order-success', loadChildren: './order-success/order-success.module#OrderSuccessPageModule' },
  { path: 'view-order/:id', loadChildren: './view-order/view-order.module#ViewOrderPageModule' },
  { path: 'reviews', loadChildren: './reviews/reviews.module#ReviewsPageModule' },

];*/

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}