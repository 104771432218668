import {
  Component,
  OnInit,
  Inject,
  HostListener,
  OnDestroy
} from '@angular/core';
import {
  ModalController,
  LoadingController,
  ToastController
} from '@ionic/angular';
import {
  HttpClient
} from '@angular/common/http';
import {
  StripeService
} from '../services/stripe.service';
import {
  CartService
} from '../services/cart.service';
import {
  forkJoin,
  of
} from 'rxjs';
import {
  catchError
} from 'rxjs/operators';
import {
  DOCUMENT
} from '@angular/common';
import {
  AuthenticationService
} from '../services/authentication.service';
import {
  Router
} from '@angular/router';
import {
  AppliamService
} from '../services/appliam.service';
import {
  Storage
} from '@ionic/storage';
import { PushService } from '../services/push.service';
declare var Stripe;

@Component({
  selector: 'app-card-payment-modal',
  templateUrl: './card-payment-modal.page.html',
  styleUrls: ['./card-payment-modal.page.scss'],
})
export class CardPaymentModalPage implements OnInit, OnDestroy {

  order_total: any;
  app: any = false;
  stripe = null;
  cardName: any;
  card: any;
  intent: any;
  stripe_client_id: any;
  pub_key: any;
  cart: any;
  user: any;
  delivery_method: any;
  delivery_charge: any;
  email: string;
  name: string;
  phone: string;
  notes: string;
  delivery_address: any;
  cart_total: any;
  loading: any;
  making_payment: boolean = false;
  fatal_error: boolean = false;
  push_token: any = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public modalCtrl: ModalController,
    public http: HttpClient,
    public stripeService: StripeService,
    public cartService: CartService,
    public auth: AuthenticationService,
    public loadingController: LoadingController,
    public router: Router,
    public toastController: ToastController,
    public appliam: AppliamService,
    public storage: Storage,
    public pushService: PushService
  ) {}

  async ngOnInit() {
    const modalState = {
      modal : true,
      desc : 'Fake modal state for back button close'
    };
    history.pushState(modalState, null);
    this.loading = await this.loadingController.create({
      message: 'Loading'
    });
    await this.loading.present();
    //maybe switch forkjoin to combineLatest() and use behaviour subjects for both? this way we can prefire the stripe service at checkout in the constructor / everytime total changes then emmit the latest value here for speed :)
    forkJoin({
      payment_intent: this.stripeService.getStripePaymentIntent(this.order_total),
      pub_key: this.stripeService.getPubKey()
    }).pipe(catchError(error => of (error))).subscribe(res => {
      console.log({
        res
      });
      this.pub_key = res.pub_key.key;
      this.intent = res.payment_intent.intent;
      this.stripe_client_id = res.payment_intent.stripe_client_id;
      this.setupStripe();
    });

    this.cartService.get_cart.subscribe(cart => {
      this.cart = cart;
    });

    this.auth.user.subscribe(user => {
      this.user = user;
    });

    this.pushService.token.subscribe(token => {
      this.push_token = token;
    });

    this.appliam.app.subscribe(app => {
      this.app = app;
      console.log({
        app
      });
    });
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  @HostListener('window:popstate')
  dismissModal() {
    this.modalCtrl.dismiss();
  }

  setupStripe() {
    this.stripe = Stripe(this.pub_key, {
      stripeAccount: this.stripe_client_id
    });
    let text_color = getComputedStyle(this.document.body).getPropertyValue('--ion-text-color');
    let danger_color = getComputedStyle(this.document.body).getPropertyValue('--ion-color-danger');
    let background_color = getComputedStyle(this.document.body).getPropertyValue('--background');
    let elements = this.stripe.elements();
    var elementStyles = {
      base: {
        iconColor: text_color,
        color: text_color,
        fontSize: "17px",
        fontSmoothing: "antialiased",

        "::placeholder": {
          color: text_color
        },
        ":-webkit-autofill": {
          color: text_color,
          backgroundColor: background_color
        }
      },
      invalid: {
        iconColor: danger_color,
        color: danger_color
      }
    };

    var elementClasses = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
    };

    this.card = elements.create('card', {
      style: elementStyles,
      classes: elementClasses,
    });

    this.card.mount('#card');

    var form = document.getElementById('payment-form');
    form.addEventListener('submit', async event => {
      event.preventDefault();
      this.making_payment = true;
      this.loading = await this.loadingController.create({
        message: 'Making Payment'
      });
      await this.loading.present();
      this.stripe.handleCardPayment(
        this.intent.client_secret, this.card, {
          payment_method_data: {
            billing_details: {
              name: this.cardName
            }
          }
        }
      ).then(async result => {
        this.loading.dismiss();
        if (result.error) {
          // PAYMENT ERROR
          this.making_payment = false;
          const toast = await this.toastController.create({
            message: result.error.message,
            duration: 5000,
            color: 'danger',
            showCloseButton: true
          });
          toast.present();
        } else {
          // The payment has succeeded. Display a success message.
          //SEND API REQUEST TO BACK END WITH PAYMENT INTENT AND BOOKING REF
          //alert("PAYMENT SUCCESSFUL");
          this.loading = await this.loadingController.create({
            message: 'Submitting Order'
          });
          await this.loading.present();
          console.log('STRIPE RESULT ', result);
          let data = {
            payment_intent: result.paymentIntent,
            total_paid: this.order_total,
            order_total: this.cart_total,
            push_token: this.push_token,
            delivery_method: this.delivery_method,
            cart: this.cart,
            delivery_charge: this.delivery_charge,
            email: this.email,
            phone: this.phone,
            name: this.name,
            notes: this.notes,
            delivery_address: this.delivery_address
          }
          console.log('sending data -> ', data);
          this.stripeService.sendStripePaymentIntent(data).subscribe(async (res: any) => {
              console.log({
                res
              });
              this.making_payment = false;
              this.loading.dismiss();
              if (res.success) {
                //navigate to order success screen!
                //empty cart
                this.cartService.empty();
                this.storage.set('RECENT_ORDER', JSON.stringify(res.order)).then(res => {
                  this.router.navigate(['/order-success']);
                  this.dismiss();
                });
              } else {
                //payment intent failed - inform customer - shouldnt happen , but just in case :)
                const toast = await this.toastController.create({
                  message: 'Payment failed, please try again',
                  duration: 5000,
                  color: 'danger',
                  showCloseButton: true
                });
                toast.present();
              }

            },
            async error => {
              this.making_payment = false;
              this.loading.dismiss();
              console.log('subscription error');
              console.error(error);
              this.fatal_error = true;
              const toast = await this.toastController.create({
                message: 'Your payment was successful, but something went wrong while sending your order to our servers. Please phone the restaurant',
                color: 'danger',
                showCloseButton: true
              });
              toast.present();
            });
        }
      });
    });
    this.loading.dismiss();
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

}