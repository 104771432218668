import {
  Injectable
} from '@angular/core';
import {
  firebase
} from '@firebase/app';
import '@firebase/messaging';
import {
  Platform,
  ToastController
} from '@ionic/angular';
import {
  AuthenticationService
} from './authentication.service';
import {
  HttpClient
} from '@angular/common/http';
import {
  AppliamService
} from './appliam.service';
import {
  Firebase
} from '@ionic-native/firebase/ngx';
import {
  BehaviorSubject
} from 'rxjs';
import * as firebaseConfig from "../../assets/firebase"

@Injectable({
  providedIn: 'root'
})
export class PushService {

  apiURL = 'https://dash.appliam.com/api/';

  user_id = null;

  firebaseConfig: any = {};

  token = new BehaviorSubject(null);

  constructor(
    public platform: Platform,
    public http: HttpClient,
    public auth: AuthenticationService,
    public appliam: AppliamService,
    public firebaseNative: Firebase,
    public toastController: ToastController
  ) {
    this.firebaseConfig = firebaseConfig.default;

    this.auth.user.subscribe((user: any) => {
      if (user) {
        this.user_id = user.id;
      }
    });
  }

  init(): Promise < void > {
    return new Promise < void > ((resolve, reject) => {
      navigator.serviceWorker.ready.then((registration) => {
        // Don't crash an error if messaging not supported
        if (!firebase.messaging.isSupported()) {
          resolve();
          return;
        }
        const messaging = firebase.messaging();

        messaging.useServiceWorker(registration);

        messaging.usePublicVapidKey(
          this.firebaseConfig.vapidKey
        );

        messaging.onMessage((payload) => {
          //Message recieved in foregroud... show the user
          console.log('Message received. ', payload);
          this.presentToast(payload);
        });

        // Handle token refresh
        messaging.onTokenRefresh(() => {
          messaging.getToken().then(
            (refreshedToken: string) => {
              console.log(refreshedToken);
              this.token.next(refreshedToken);
              //maybe create refresh token api route where we pass new & old token to remove and add :)
            }).catch((err) => {
            console.error(err);
          });
        });
        resolve();
      }).catch((err) => {
        console.error('[PUSH] SW REG ERROR => ', err);
        reject(err);
      });
    });
  }

  requestPermission(): Promise < void > {
    return new Promise < void > (async (resolve, reject) => {
      if (!Notification) {
        console.log('[PUSH] Notification is false');
        resolve();
        return;
      }
      if (!firebase.messaging.isSupported()) {
        console.log('[PUSH] firebase msg is not supported');
        resolve();
        return;
      }
      try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();
        const token = await messaging.getToken();
        console.log('[PUSH] TOKEN => ', token);
        this.token.next(token);
        this.syncToken();
        resolve();
      } catch (err) {
        // No notifications granted
        console.error('[PUSH] SOMETHING WENT WRONG WHILE REQUESTING PERMISSION');
        console.error(err);
        reject(err);
      }
    });
  }

  syncToken() {
    this.sendTokenToServer(this.token.value).subscribe(res => {
      console.log('[PUSH] SEND TO SERVER RES ', res);
    });
  }

  sendTokenToServer(token) {
    let device = 'Web';
    if (this.platform.is('cordova')) {
      if (this.platform.is('ios')) {
        device = 'iOS';
      } else {
        if (this.platform.is('android')) {
          device = 'Android'
        }
      }
    }
    let data = {
      app_id: this.appliam.appID,
      user_id: this.user_id,
      token: token,
      device_type: device
    };
    console.log({
      data
    });
    return this.http.post(`${this.apiURL}app/push/token`, data);
  }

  async getToken() {
    //NATIVE DEVICES
    let token: any = false;

    if (this.platform.is('android')) {
      token = await this.firebaseNative.getToken();
    }

    if (this.platform.is('ios')) {
      token = await this.firebaseNative.getToken();
      await this.firebaseNative.grantPermission();
    }
    if (token !== false) {
      this.token.next(token);
      this.sendTokenToServer(token);
    }

  }

  onNotifications() {
    return this.firebaseNative.onNotificationOpen();
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 3000
    });
    toast.present();
  }

}