import {
  Injectable
} from '@angular/core';
import {
  BehaviorSubject
} from 'rxjs';
import {
  HttpClient
} from '@angular/common/http';
import {
  AppliamService
} from './appliam.service';
import { AuthenticationService } from './authentication.service';

import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class StripeService {

  STRIPE_MODE = environment.production ? 'live' : 'sandbox'; //live

  pub_key = new BehaviorSubject(null);

  user: any;

  apiUrl = 'https://dash.appliam.com/api/';

  constructor(
    public http: HttpClient,
    public appliam: AppliamService,
    public auth: AuthenticationService
  ) {
    console.log('[STRIPE SERVICE] MODE => ', this.STRIPE_MODE);
    this.getPubKey().subscribe((res: any) => {
      if (res.success) {
        this.pub_key.next(res.key);
      }
    });
    console.log('GETTING STRIPE ACCOUNT');
    this.getStripeAccount().subscribe(res => {
      console.log('stripe account', res);
    });

    this.auth.user.subscribe(user => {
      this.user = user;
    })
  }

  getPubKey() {
    return this.http.post(`${this.apiUrl}payments/key/${this.appliam.appID}`, {
      mode: this.STRIPE_MODE
    });
  }

  getStripeAccount() {
    return this.http.get(`${this.apiUrl}stripe/account/${this.appliam.appID}`);
  }

  getStripePaymentIntent(amount) {
    return this.http.post(`${this.apiUrl}stripe/payment/intent`, {
      production: this.STRIPE_MODE == 'live' ? true : false,
      app_id: this.appliam.appID,
      amount: amount
    });
  }

  sendStripePaymentIntent(data) {
      return this.http.post(`${this.apiUrl}stripe/takeaway/intent/paid`, {
        production: this.STRIPE_MODE == 'live' ? true : false,
        app_id: this.appliam.appID,
        user_id: this.user ? this.user.id : null,
        payment_intent: data.payment_intent,
        total_paid: data.total_paid,
        order_total: data.order_total,
        push_token: data.push_token,
        delivery_method: data.delivery_method,
        cart: data.cart,
        delivery_charge: data.delivery_charge,
        email: data.email,
        phone: data.phone,
        name: data.name,
        notes: data.notes,
        delivery_address: data.delivery_address
      });
  }
}