import {
  Component,
  OnInit,
} from '@angular/core';

import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed } from '@capacitor/core';

import {
  Platform,
  ToastController
} from '@ionic/angular';
import {
  SplashScreen
} from '@ionic-native/splash-screen/ngx';
import {
  StatusBar
} from '@ionic-native/status-bar/ngx';
import {
  AuthenticationService
} from './services/authentication.service';
import {
  CartService
} from './services/cart.service';
import {
  firebase
} from '@firebase/app';
import {
  PushService
} from './services/push.service';
import {
  Storage
} from '@ionic/storage';
import { AppliamService } from './services/appliam.service';

const { PushNotifications } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  logged_in: boolean = false;

  total_cart_items: number = 0;

  dark_mode: boolean = false;

  app: any = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public auth: AuthenticationService,
    public cart: CartService,
    public pushService: PushService,
    public toastController: ToastController,
    public storage: Storage,
    public appliam: AppliamService
  ) {
    this.initializeApp();
  }

  async ngOnInit() {

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then( result => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: PushNotificationToken) => {
        alert('Push registration success, token: ' + token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotification) => {
        alert('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        alert('Push action performed: ' + JSON.stringify(notification));
      }
    );
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.

    // Use matchMedia to check the user preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addListener((mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));

    this.appliam.app.subscribe((app: any) => {
      if (app) {
        this.app = app;
      }
    });

    this.auth.authState.subscribe(aState => {
      this.logged_in = aState;
      console.log({
        aState
      });
    });

    this.cart.total_items.subscribe(t => {
      this.total_cart_items = t;
    });

    this.storage.get('DARK_MODE').then(res => {
      if (res !== null) {
        //has dark mode set
        this.toggleDarkTheme(res);
      } else {
        //no dark mode, set auto config
        this.toggleDarkTheme(prefersDark.matches);
      }
    }).catch(err => {
      console.error(err)
    });

    firebase.initializeApp(this.pushService.firebaseConfig);

    await this.pushService.init().catch(err => {
      console.error(err)
    });
  }

  ngAfterViewInit() {
    this.platform.ready().then(async () => {
      await this.pushService.requestPermission().catch(err => {
        console.error('[APP COMPONENT] ERROR WHILE REQUESTING PERMISSION');
        console.error(err);
      });
    });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleLightContent(); //white text for splashscreen
      this.splashScreen.hide();

      if (this.platform.is('cordova')) {
        //NATIVE STUFF
        this.pushService.onNotifications().subscribe(
          (msg) => {
            console.log({
              msg
            });
            if (this.platform.is('ios')) {
              this.pushService.presentToast(msg.aps.alert);
            } else {
              this.pushService.presentToast(msg.body);
            }
          });

        this.pushService.getToken();
        //END NATIVE STUFF
      }

    });
  }

  logout() {
    this.auth.logout();
  }

  // Add or remove the "dark" class based on if the media query matches
  toggleDarkTheme(shouldAdd) {
    var metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (shouldAdd) {
      //dark mode
      this.statusBar.styleLightContent();
      metaThemeColor.setAttribute("content", '#232323');
    } else {
      //light mode
      this.statusBar.styleDefault();
      metaThemeColor.setAttribute("content", '#f4f5f8');
    }
    this.dark_mode = shouldAdd;
    document.body.classList.toggle('dark', shouldAdd);
  }

  toggleDarkSetting() {
    this.toggleDarkTheme(this.dark_mode);
    this.storage.set('DARK_MODE', this.dark_mode);
  }
}