import {
  Component,
  OnInit,
  OnDestroy,
  HostListener
} from '@angular/core';
import {
  ModalController,
  LoadingController,
  ToastController
} from '@ionic/angular';
import {
  AddressService
} from '../services/address.service';
import {
  FormControl,
  Validators,
  FormBuilder
} from '@angular/forms';
import {
  Router
} from '@angular/router';
import { MenuService } from '../services/menu.service';

@Component({
  selector: 'app-add-address-modal',
  templateUrl: './add-address-modal.page.html',
  styleUrls: ['./add-address-modal.page.scss'],
})
export class AddAddressModalPage implements OnInit, OnDestroy {

  address_form;
  config;
  constructor(
    public modalCtrl: ModalController,
    public addressService: AddressService,
    public formBuilder: FormBuilder,
    public loadingController: LoadingController,
    public toastController: ToastController,
    public router: Router,
    public menuService: MenuService
  ) {
    this.address_form = this.formBuilder.group({
      address1: new FormControl('', Validators.compose([Validators.maxLength(255), Validators.required])),
      address2: new FormControl('', Validators.compose([Validators.maxLength(255)])),
      city: new FormControl('', Validators.compose([Validators.maxLength(255), Validators.required])),
      county: new FormControl('', Validators.compose([Validators.maxLength(255), Validators.required])),
      postcode: new FormControl('', Validators.compose([Validators.maxLength(255), Validators.required])),
    })
  }

  ngOnInit() {
    const modalState = {
      modal : true,
      desc : 'Fake modal state for back button close'
    };
    history.pushState(modalState, null);
    this.menuService.config.subscribe(config => {
      this.config = config;
    });
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  @HostListener('window:popstate')
  dismissModal() {
    this.modalCtrl.dismiss();
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  async save() {
    if (this.address_form.valid) {
      const loading = await this.loadingController.create({
        message: 'Adding Address'
      });
      await loading.present();
      this.addressService.storeAddress(this.address_form.value).then(async data => {
        this.address_form.reset(); //clear the form
        const toast = await this.toastController.create({
          message: 'Address created successfully!',
          duration: 4000,
          color: 'primary',
          showCloseButton: true
        });
        toast.present();
        this.dismiss();
      }).catch(async error => {
        console.error(error);
        if (error.message) {
          const toast = await this.toastController.create({
            message: error.message,
            duration: 8000,
            color: 'danger',
            showCloseButton: true
          });
          toast.present();
        } else {
          const toast = await this.toastController.create({
            message: 'There was an error while adding the address',
            duration: 8000,
            color: 'danger',
            showCloseButton: true
          });
          toast.present();
        }
        
      }).finally(() => {
        loading.dismiss();
      });

    } else {
      const toast = await this.toastController.create({
        message: 'Ooops, looks like there is something wrong with the details provided, please double check and try again!',
        duration: 4000,
        color: 'danger',
        showCloseButton: true
      });
      toast.present();
    }
  }

}